import { defineStore } from 'pinia';
import { watch, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGeoIPStore } from './geoip';
import { checkPlan, verifyTokenUser, checkPaid } from '@/services/backend';
import { verifyToken } from '@/services/jose';
import { updateUrlQuery } from '@/services/windowAPI'

const quantityLimit = import.meta.env.VITE_QUANTITY_LIMIT;
const routerCouponPrefixes = import.meta.env.VITE_ROUTER_COUPON_PREFIXES;
const enableIndividualServers = import.meta.env.VITE_ENABLE_INDIVIDUAL_SERVERS === 'true';

export const useCheckoutStore = defineStore('checkout', () => {
  const geoIPStore = useGeoIPStore();
  const route = useRoute();
  const router = useRouter();
  const planDescription = ref({});

  const state = ref({ // refs become state properties in setup stores
    firstName: '',
    lastName: '',
    email: '',
    emailRepeat: '',
    plan: route.query.plan || 'speedify-unlimited-monthly',
    country: geoIPStore.details.country || 'US',
    postalCode: '',
    currency: geoIPStore.details.currency || 'USD',
    quantity: route.query.quantity || 1,
    serverCode: '',
    serverName: '',
    servers: route.query.servers || 0,
    price: null,
    upsold: false,
    vatNumber: '',
    loggedIn: null,
    isPaid: null,
    // planTrial is for free trials that are attached to the selected plan
    //  - covers cardless trials as well, but not all trials are cardless
    planTrial: false,
    // couponTrial tracks whether the coupon that's redeemed offers a trial
    //  - we turn this off if customer redeems a different type of coupon
    couponTrial: false,
    trialLength: '',
    coupon: {
      success: false,
      invalid: false,
      invalidMessage: 'Invalid coupon code',
      // used to v-model coupon input and fed to validatedCode when recurlyJS checks it
      inputtedCode: route.query.coupon || '',
      validatedCode: '',
      routerCode: '', // supplied when manually inputted coupon belongs to a router coupon batch
    },
  });

  const getPlanDescription = async () => {
    const response = await checkPlan(state.value.plan);
    if (response.status === 404) {
      router.push({ query: { plan: 'speedify-unlimited-monthly' } });
    }
    if (!response.err) {
      planDescription.value = await response.json();
    }
  };

  // anti-pattern on a vue SPA, but recurlyJS doesn't support detaching subscriptions from its checkoutPricing instance.
  // so we opt to remove servers for example by cutting the 'servers' query from the url and reload.
  // hiding the relevant data-recurly input from the DOM should be sufficient,
  // but introduces significant performance issues, like crashing the tab if you play with the tab's history.
  const reloadCartWithoutServers = () => {
    router.push({ name: 'CheckoutCart' } + window.location.search)
      .then(() => {
        const newQueryString = Object.entries(route.query)
          .filter(q => !q[0].match(/servers/i))
          .map(arr => arr.join('='))
          .join('&');
        window.location.search = newQueryString;
      });
  };

  // We set up watchers here since html min/max attributes are not capable of handling
  // manual user changes to the input field. If a user manually changes it to a 0
  // or a negative number, set it back to 1
  // or squash floats down to nearest integer (3.5 -> 3)
  watch(
    () => state.value.quantity,
    newSubscriptionQuantity => {
      if (newSubscriptionQuantity <= 0) {
        state.value.quantity = 1;
      } else if (newSubscriptionQuantity > quantityLimit) {
        state.value.quantity = quantityLimit;
      } else if (!Number.isInteger(newSubscriptionQuantity)) {
        state.value.quantity = Math.floor(newSubscriptionQuantity);
      }
      // workaround: https://github.com/Connectify/speedify-cart/pull/340#discussion_r1525411310
      updateUrlQuery('quantity', state.value.quantity);
    },
  );

  watch(
    () => state.value.servers,
    newServerQuantity => {
      if (newServerQuantity <= 0) {
        state.value.servers = 1;
      } else if (newServerQuantity > quantityLimit) {
        state.value.servers = quantityLimit;
      } else if (!Number.isInteger(newServerQuantity)) {
        state.value.servers = Math.floor(newServerQuantity);
      }
      // workaround: https://github.com/Connectify/speedify-cart/pull/340#discussion_r1525411310
      updateUrlQuery('servers', state.value.servers);
    },
  );

  watch(() => geoIPStore.details.currency, () => {
    state.value.currency = geoIPStore.details.currency;
  });

  watch(() => geoIPStore.details.zipCode, () => {
    // for now, will only run when geoIPStore.refresh is invoked
    state.value.postalCode = geoIPStore.details.zipCode;
  });

  // guard server purchases against free users on page load if logged in or at purchase time
  watch(() => state.value.isPaid, newIsPaid => {
    if (newIsPaid === false && state.value.plan.match(/server/i)) {
      const term = state.value.plan.includes('monthly') ? 'monthly' : 'yearly';
      router.push({ query: { plan: `speedify-teams-unlimited-${term}`, servers: 1, coupon: state.value.coupon.validatedCode, addedTeam: 1 } });
    }
  });

  const update = (obj) => {
    for (const key in obj) {
      state.value[key] = obj[key];
    }
  };

  const ignoreUsersPaidStatus = state.value.plan.match(/router|server/);

  // used to check coupon codes from route/url query
  const couponIsVendorRouter = computed(() => {
    return routerCouponPrefixes.split(',').some((prefix) => state.value.coupon.inputtedCode.startsWith(prefix));
  });

  // grab info from cookie about the user. move this to a new store when we're interested in more than just email
  (async () => {
    if (await verifyTokenUser()) {
      const tokenPayload = await verifyToken();
      if (tokenPayload?.userid) {
        state.value.loggedIn = true;
        state.value.email = tokenPayload.email;
        state.value.emailRepeat = tokenPayload.email;
        state.value.isPaid = await checkPaid();
      }
    }
  })();

  // computed values
  const subscriptionProductRequiresCoupon = computed(() => {
    const list = [
      'speedify-unlimited-monthly-cardless-6m-trial',
      'speedify-vtx-unlimited-12m-cardless',
      'speedify-unlimited-monthly-cardless',
    ];
    return list.some((e) => state.value.plan == e);
  });

  const subscriptionProductEligibleForQuantityChange = computed(() => {
    const list = ['team', 'router', 'reseller'];
    return list.some((e) => state.value.plan.match(e));
  });

  const serversAvailable = computed(() => state.value.servers && (state.value.plan.includes('team') || enableIndividualServers));

  const exitOfferRelevant = computed(() => {
    // teams and routers are completely distinct categories of products from an individual nonrenewing pass
    // so don't make the customer think they're getting a deal on those
    const list = ['team', 'router'];
    return !list.some((e) => state.value.plan.match(e));
  });

  const freeTrialActive = computed(() => {
    return state.value.planTrial || state.value.couponTrial;
  });

  return {
    checkoutData: state,
    getPlanDescription,
    planDescription,
    update,
    reloadCartWithoutServers,
    ignoreUsersPaidStatus,
    couponIsVendorRouter,
    subscriptionProductRequiresCoupon,
    subscriptionProductEligibleForQuantityChange,
    serversAvailable,
    exitOfferRelevant,
    freeTrialActive,
  };
});
