<template>
  <h4 class="accordion-header" id="headingThree">
    <button
      type="button"
      class="accordion-button collapsed d-flex flex-row justify-content-between gap-2 stm-btn-accordion-venmo"
      data-bs-toggle="collapse"
      data-bs-target="#accordion-venmo"
      aria-expanded="false"
      aria-controls="accordion-venmo"
    >
      <div class="d-flex justify-content-between w-100">
        <div>Venmo</div>
        <img alt="Venmo supported" class="icon" :src="venmoSvg" />
      </div>
    </button>
  </h4>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
  <div
    id="accordion-venmo"
    class="accordion-collapse collapse"
    aria-labelledby="headingOne"
    data-bs-parent="#paymentAccordion"
    @click="$emit('showVuelidate')"
  >
    <div class="alert alert-danger mt-3 mb-0 stm-msg-venmo-error mx-4" role="alert" v-if="state.purchaseFailed || state.venmoError">
      <i class="fas fa-exclamation-circle"></i>
      &nbsp;Error
      <hr />
      <p v-if="state.purchaseFailed">
        Unable to process purchase:
        <span v-html="state.failureError"></span>
      </p>
      <div v-if="state.venmoError">
        <p>
          Venmo Error: {{ state.venmoError }}
          <br />
          If this persists, please contact
          <a href="support@speedify.com">support@speedify.com</a>
        </p>
      </div>
    </div>
    <div class="accordion-body card-element justify-content-center">
      <button
        type="button"
        id="venmo-button"
        class="accordion-body btn btn-lg btn-block w-100 venmo-button-color stm-btn-venmo-start"
        style="max-height: 50%"
        @click="handleVenmoClick"
        :disabled="disableButton || state.processing"
      >
        <span v-if="state.processing" class="text-white">Processing with Venmo... </span>
        <img class="h-50 venmo-logo-color" :src="venmoLogoSvg" alt="Venmo full logo" aria-label="venmo" v-else />
      </button>
      <div class="d-flex flex-nowrap justify-content-around w-100 text-center mt-3">
        <div class="small col">
          <img alt="secure" class="icon" :src="lockSvg" />
          Encrypted and secure payments
        </div>
        <div class="small col align-top">
          <img alt="money back" class="icon" :src="clockRotateLeftSvg" />
          30-Day Money Back Guarantee
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted } from 'vue';
import * as Sentry from '@sentry/vue';
import { createPurchase } from '@/services/backend';
import { useAnalyticsStore } from '@/stores/analytics';
import { useCheckoutStore } from '@/stores/checkout';
import getLocale from '@/services/getLocale';
import { orderCompleteRedirect } from '@/services/orderCompleteURL';

import venmoSvg from '@/assets/svg/venmo.svg';
import venmoLogoSvg from '@/assets/svg/venmo-logo.svg';
import lockSvg from '@/assets/svg/lock.svg';
import clockRotateLeftSvg from '@/assets/svg/clock-rotate-left.svg';

const analyticsStore = useAnalyticsStore();
const { checkoutData } = useCheckoutStore();

const locale = getLocale();

const props = defineProps({
  disableButton: Boolean,
});

const emit = defineEmits(['paymentProcessing', 'paymentFailed', 'showVuelidate']);

const state = reactive({
  purchaseFailed: false,
  failureError: '',
  venmoError: '',
  processing: false,
});

let venmo;

const handleVenmoClick = () => {
  venmo.start();
};

onMounted(() => {
  venmo = recurly.Venmo({
    form: document.getElementById('form'),
    braintree: {
      clientAuthorization: import.meta.env.VITE_BRAINTREE_KEY,
      webAuthentication: true,
    },
    display: {
      amount: checkoutData.price,
      currency: 'USD',
      displayName: 'Speedify',
      locale: 'en_US',
    },
  });
  
  venmo.on('error', err => {
    const { code } = err.cause;
    // don't display if the user simply canceled auth
    if (code === "VENMO_TOKENIZATION_CANCELED_BY_MERCHANT"
      || code === "FRAME_SERVICE_FRAME_CLOSED") {
      return;
    }
    Sentry.captureException(err);
    state.venmoError = err;
  });
  
  venmo.on('token', async token => {
    state.processing = true;
    emit('paymentProcessing');
    const result = await createPurchase(checkoutData, undefined, token.id, analyticsStore.details, 'venmo');
    if (result.status === 'success') {
      orderCompleteRedirect(checkoutData.loggedIn);
    } else if (result.error?.errorCode === 'ERROR_MISSING_SPEEDIFY_LICENSE'){
      checkoutData.isPaid = false;
    } else {
      state.purchaseFailed = true;
      state.failureError = result.error.message;
      state.processing = false;
      emit('paymentFailed');
    }
  });
});
</script>
